import React, { useState } from "react";
//import { Link } from "gatsby";
import PropTypes from "prop-types";
import Logo from "../assets/logo/vanwagon-ph-logo.png";
import Helmet from "react-helmet"

const Header = ({ siteTitle }) => {
	const [navigationOpen, setNavigationOpen] = useState("inactive");

	const scrollDown = (id) => {

		setTimeout(function(){
			let element = document.getElementById(id);
			element.scrollIntoView({ behavior: "smooth" });
		}, 0)

	};

	return(
		<>
			<Helmet>
				<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
			</Helmet>
			<header>
				<div className="header-container">
					<a
						href="/"
					>
						<img
							src={Logo}
							alt="vanwagon-logo"
							className="vanwagon-header-logo"
							/>
					</a>

					<nav className="navigation-menu-icon">
						<button onClick={() => setNavigationOpen(navigationOpen === "active" ? "inactive" : "active")}>
							<i
								className="fa fa-bars"

							> </i>
						</button>
					</nav>
					<nav className={`navigation-menu ${navigationOpen}`}>

						<button
							className="navigation-item"
							onClick={() => scrollDown("main-section-container") || setNavigationOpen("inactive")}
						>HOME</button>

						<button
							className="navigation-item"
							onClick={() => scrollDown("middle-section-container") || setNavigationOpen("inactive")}
						>OUR TRANSPORT PARTNERS</button>

						<button
							className="navigation-item"
							onClick={() => scrollDown("blog-section-container") || setNavigationOpen("inactive")}
						>BLOGS</button>

						<button
							className="navigation-item"
							onClick={() => scrollDown("about-us-section-container") || setNavigationOpen("inactive")}
						>ABOUT US</button>

					</nav>
				</div>
			</header>
		</>
	);
}

Header.propTypes = {
	siteTitle: PropTypes.string,
};

Header.defaultProps = {
	siteTitle: ``,
};

export default Header;
